import './jquery.min.js';
// import './cookieconsent.umd.js';
import './cookieconsent-config.js';
import './dotlottie-player-normal.js';
// import './swiper-bundle.min.js';
import './_readMore.js';


// Blog Scroller
if (document.querySelector('.blogImage')) {
  document.addEventListener("DOMContentLoaded", function() {
    const imageContainers = document.querySelectorAll('.blogImage');

    imageContainers.forEach(container => {
      const img = container.querySelector('img');

      // Function to handle the image load logic
      const handleImageLoad = function() {
        const containerHeight = container.clientHeight;
        const imageHeight = img.clientHeight;
        const translateY = imageHeight - containerHeight;

        img.style.setProperty('--translateY', `-${translateY}px`);
      };

      // Check if image is already loaded
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
      }

      window.addEventListener('resize', () => {
        handleImageLoad();
      });

      container.addEventListener('mouseover', function() {
        const translateY = img.style.getPropertyValue('--translateY');
        img.style.transform = `translateY(${translateY})`;
      });

      container.addEventListener('mouseout', function() {
        img.style.transform = 'translateY(0)';
      });
    });
  });
}


// Navbar Scroll
document.addEventListener('DOMContentLoaded', function() {
  const navbar = document.querySelector('.nav-header');

  // Function to check the scroll position and update the class
  function updateNavbarClass() {
    if (window.scrollY > 0) {
      navbar.classList.remove('top-nav');
    } else {
      navbar.classList.add('top-nav');
    }
  }

  // Initial check when the page loads
  updateNavbarClass();

  // Event listener to update the class on scroll
  window.addEventListener('scroll', updateNavbarClass);
});



// Background Animation
if (document.querySelector('#background')) {
  const canvas = document.getElementById('background');
  const ctx = canvas.getContext("2d", { alpha: true });

  let boxes, points;

  function updateBoxesAndPoints() {
      boxes = Array.from(document.querySelectorAll(".attach")).filter(isVisible);
      points = boxes.map((box, index) => (index % 2 === 0 ? getOffsetEven(box) : getOffsetOdd(box)));
  }

  function resizeCanvas() {
      
      canvas.width = document.body.clientWidth / 10;
      canvas.height = document.body.clientHeight  / 10;
      ctx.scale(0.1, 0.1); // Scale drawing context to match canvas size reduction

      updateBoxesAndPoints();
  }

  resizeCanvas();

  let time = 0;

  function isVisible(element) {
      while (element) {
          if (window.getComputedStyle(element).display === 'none') {
              return false;
          }
          element = element.parentElement;
      }
      return true;
  }

  function getOffsetEven(el) {
    const rect = el.getBoundingClientRect();
    return {
        x: rect.left + window.scrollX,
        y: rect.top + rect.height / 2 + window.scrollY
    };
}

function getOffsetOdd(el) {
    const rect = el.getBoundingClientRect();
    return {
        x: rect.right + window.scrollX,
        y: rect.top + rect.height / 2 + window.scrollY
    };
}

  function jitterPoint(point, time, magnitude = 2) {
      return {
          x: point.x + Math.sin(time) * magnitude,
          y: point.y + Math.cos(time) * magnitude
      };
  }

  function drawSpline(points, color, lineWidth, offset) {
      if (points.length < 2) return;

      ctx.beginPath();
      const start = jitterPoint({ x: points[0].x, y: points[0].y - 200 }, time, 2);
      ctx.moveTo(start.x, start.y);

      for (let i = 1; i < points.length - 1; i++) {
          const jitteredPoint = jitterPoint(points[i], time + i, 10);
          const cp1x = (jitteredPoint.x + points[i + 1].x) / 2;
          const cp1y = (jitteredPoint.y + points[i + 1].y) / 2;
          ctx.quadraticCurveTo(jitteredPoint.x, jitteredPoint.y, cp1x + (10 * offset), cp1y - (10 * offset));
      }

      const end = jitterPoint({ x: points[points.length - 1].x, y: points[points.length - 1].y }, time, 2);
      ctx.lineTo(end.x, end.y);

      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
  }

  function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < 8; i++) {
          const hue = (220 + i * 10) % 360;
          const lightness = 50 + Math.sin(time + i) * 50;
          const color = `hsl(${hue}, 100%, ${lightness}%)`;
          drawSpline(points, color, 4 + i, i - 4);
      }

      time += 0.02;
      requestAnimationFrame(animate);
  }

  animate();


  window.addEventListener('resize', () => {
    resizeCanvas();
  });
  window.addEventListener("load", (event) => {
    resizeCanvas();
  });
}


// // Testimonial Swiper
// if (document.querySelector('.testimonial')) {
// var swiper1 = new Swiper('.testimonialSwiper1', {
//   slidesPerView: '1',
//   centeredSlides: false,
//   autoplay: {
//     delay: 5000,
//   },
//   loop: true,
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
// });

// var swiper2 = new Swiper('.testimonialSwiper2', {
//   slidesPerView: '1',
//   centeredSlides: true,
//   effect: "fade",
//   loop: true,
//   allowTouchMove: false,
//   fadeEffect: {
//     crossFade: true
//   },
//   pagination: {
//     el: '.swiper-pagination',
//   },
// });
// swiper1.controller.control = swiper2;
// }

// // Portfolio Swiper
// if (document.querySelector('.portfolio')) {
// new Swiper(".mySwiper2", {
//   effect: "cards",
 
//     grabCursor: true,
//     slidesPerView: 1,
//     spaceBetween: 30,
//     loop: true,
//     centeredSlides: true,

//     navigation: {
//       nextEl: ".swiper-button-next",
//       prevEl: ".swiper-button-prev",
//     },
// });

// }

// BlogSlider Swiper
if (document.querySelector('.blogSlider')) {
new Swiper('.blog-slider', {
  spaceBetween: 30,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  loop: true,
  // mousewheel: {
  //   invert: false,
  // },
  // autoHeight: true,
  pagination: {
    el: '.blog-slider__pagination',
    clickable: false,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoplay: {
    delay: 8000,
    disableOnInteraction: true,
  }
});
}

  

// Navbar
// Toggle to show and hide navbar menu
const navbarMenu = document.getElementById("menu");
const burgerMenu = document.getElementById("burger");
const body = document.querySelector("body");
const dropdowncontent = document.querySelector(".dropdown-content");

burgerMenu.addEventListener("click", () => {
  navbarMenu.classList.toggle("is-active");
  burgerMenu.classList.toggle("is-active");
  if (body.style.overflow === "hidden") {
    body.style.overflow = "visible";
  } else {
    body.style.overflow = "hidden";
  }
});

// Toggle to show and hide dropdown menu
const dropdown = document.querySelectorAll(".dropdown");

dropdown.forEach((item) => {
  const dropdownToggle = item.querySelector(".dropdown-toggle");

  dropdownToggle.addEventListener("click", () => {
    const dropdownShow = document.querySelector(".dropdown-show");
    toggleDropdownItem(item);

    // Remove 'dropdown-show' class from other dropdown
    if (dropdownShow && dropdownShow != item) {
      toggleDropdownItem(dropdownShow);
    }
  });
});

// Function to display the dropdown menu
const toggleDropdownItem = (item) => {
  const dropdownContent = item.querySelector(".dropdown-content");

  // Remove other dropdown that have 'dropdown-show' class
  if (item.classList.contains("dropdown-show")) {
    dropdownContent.removeAttribute("style");
    item.classList.remove("dropdown-show");
  } else {
    // Added max-height on active 'dropdown-show' class
    dropdownContent.style.height = dropdownContent.scrollHeight + "px";
    item.classList.add("dropdown-show");
  }
};

// Fixed dropdown menu on window resizing
window.addEventListener("resize", () => {
  if (window.innerWidth > 992) {
    document.querySelectorAll(".dropdown-content").forEach((item) => {
      item.removeAttribute("style");
    });
    dropdown.forEach((item) => {
      item.classList.remove("dropdown-show");
    });
  }
});

// Fixed navbar menu on window resizing
window.addEventListener("resize", () => {
  if (window.innerWidth > 992) {
    if (navbarMenu.classList.contains("is-active")) {
      navbarMenu.classList.remove("is-active");
      burgerMenu.classList.remove("is-active");
    }
  }
});

// Desktop Dropdown Handler for Touch Devices
// document.addEventListener('DOMContentLoaded', function() {
//   const dropdowns = document.querySelectorAll('.dropdown');

//   dropdowns.forEach(dropdown => {
//     let isTouchDevice = false;

//     // Detect if the user is on a touch device
//     window.addEventListener('touchstart', function() {
//       isTouchDevice = true;
//     });

//     // Handle click for touch devices (and make dropdown clickable)
//     dropdown.addEventListener('click', function(event) {
//       if (isTouchDevice) {
//         // Toggle 'active' class on click for touch devices
//         dropdown.classList.toggle('active');
//       } else {
//         event.preventDefault(); // Prevent click if not a touch device
//       }
//     });
//   });
// });



// Get the elements
const contactNumber = document.querySelector('.contact-number-mobile .online');
const dropdownMenu = document.querySelector('.dropdown-content-contact-mobile');

// Add event listener to the contact-number-mobile div
contactNumber.addEventListener('click', function() {
  dropdownMenu.classList.toggle('show'); // Toggle the 'show' class on each click
});

